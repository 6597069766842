import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import IntlMessages from "util/intlMessages";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import DatePicker from "react-datepicker";
import { LoadingIcon, setOnChange } from "components/special";
import adminAPI from "redux/api/admin";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const HelpContent = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [body, setBody] = useState({});
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(null);
  const [formDisabled, setFormDisabled] = useState(null);
  const changeNewContent = setOnChange(body, setBody);
  const toggleModal = () => setOpenModal(!openModal);
  const dispatch = useDispatch();
  const intl = useIntl();
  const { contentForm } = props;
  const dateFormat = "dd.MM.yyyy ";

  const submitHandler = () => {
    const onSuccess = (body) => {
      setResult(body);
      setLoading(false);
    };
    props.sendRequest(
      { body },
      {
        onSuccess,
        onRequest() {
          setLoading(true);
          setResult(null);
        },
        onFailure() {
          setLoading(false);
        },
      }
    );
  };

  useEffect(() => {
    setFormDisabled(!(body.toDate && body.fromDate));
  }, [body]);

  useEffect(() => {
    setBody({});
  }, [openModal]);

  return (
    <>
      <i className="fa fa-question-circle" onClick={toggleModal} />

      {openModal && (
        <Modal isOpen={openModal} centered size="xl" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Получить сводку аспектов</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <IntlMessages id="Contents.AstroWeeks.fromDate" />
                </div>
                <div className="col">
                  <DatePicker
                    selected={body.fromDate ? new Date(body.fromDate) : ""}
                    dateFormat={dateFormat}
                    popperPlacement="bottom-start"
                    onChange={(value) =>
                      setBody({
                        ...body,
                        [`fromDate`]: value ? value.toJSON() : null,
                      })
                    }
                    className="form-control"
                    calendarClassName="custom-calender-class"
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col">
                  <IntlMessages id="Contents.AstroWeeks.toDate" />
                </div>
                <div className="col">
                  <DatePicker
                    selected={body.toDate ? new Date(body.toDate) : ""}
                    dateFormat={dateFormat}
                    popperPlacement="bottom-start"
                    onChange={(value) =>
                      setBody({
                        ...body,
                        [`toDate`]: value ? value.toJSON() : null,
                      })
                    }
                    className="form-control"
                    calendarClassName="custom-calender-class"
                  />
                </div>
              </div>
            </div>

            {loading ? <LoadingIcon /> : null}
            {result ? (
              <div>
                <div className="mb-4">
                  <h4 className="mb-2">Совместимость</h4>
                  <div className="w-100 overflow-auto">
                    <div className="d-flex flex-nowrap">
                      {_.map(result.data, (value, key1) => {
                        const { sign, compatibilities } = value;
                        return (
                          <div key={key1} className="mr-5">
                            <h3>
                              <IntlMessages id={`AstroSigns.${sign}`} />
                            </h3>
                            <div className="text-nowrap">
                              {_.map(compatibilities, (value, index) => {
                                const key = `compatibilities-${key1}-${index}`;
                                const { sign, planet, aspectValue, category } = value;
                                const planetLocale = intl.formatMessage({
                                  id: `Planets.${planet}`,
                                });
                                return (
                                  <div key={key}>
                                    <b className="mr-1">
                                      <IntlMessages id={`AstroSigns.${sign}`} />:
                                    </b>
                                    {`${category} (${planetLocale}) - ${aspectValue}`}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <h4 className="mb-2">Темы совместимости</h4>
                  <div className="w-100 overflow-auto">
                    <div className="d-flex flex-nowrap">
                      {_.map(result.data, (value, key1) => {
                        const { sign, compatibilitiesCategories } = value;
                        return (
                          <div key={key1} className="mr-5">
                            <h3>
                              <IntlMessages id={`AstroSigns.${sign}`} />
                            </h3>
                            <div className="text-nowrap">
                              {_.map(compatibilitiesCategories, (value, index) => {
                                const key = `compatibility-category-${key1}-${index}`;
                                const { name, planet, aspectValue } = value;
                                const planetLocale = intl.formatMessage({
                                  id: `Planets.${planet}`,
                                });
                                return (
                                  <div
                                    key={key}
                                  >{`${name} (${planetLocale}) - ${aspectValue}`}</div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <h4 className="mb-2">Темы </h4>
                  <div className="w-100 overflow-auto">
                    <div className="d-flex flex-nowrap">
                      {_.map(result.data, (value, key1) => {
                        const { sign, categories } = value;
                        return (
                          <div key={key1} className="mr-5">
                            <h3>
                              <IntlMessages id={`AstroSigns.${sign}`} />
                            </h3>
                            <div className="text-nowrap">
                              {_.map(categories, (value, index) => {
                                const key = `category-${key1}-${index}`;
                                const { name, planet, aspectValue } = value;
                                const planetLocale = intl.formatMessage({
                                  id: `Planets.${planet}`,
                                });
                                return (
                                  <div
                                    key={key}
                                  >{`${name} (${planetLocale}) - ${aspectValue}`}</div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="form-group">
              <button
                type="button"
                className="form-control c-primary"
                disabled={formDisabled}
                onClick={submitHandler}
              >
                <IntlMessages id="ok" />
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  sendRequest: api.post(apiUrls.periodicQuotesHelp),
})(HelpContent);
