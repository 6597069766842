import _ from "lodash";
import React, { useState, useEffect } from "react";
import IntlMessages from "util/intlMessages";
import { ImagePreview, LocalesValues, Uploader, VideoPreview } from "components/standart";

import SwitchValue from "./SwitchValue";

import { setOnChange } from "../index";

const ExtraInfoBlock = (props) => {
  const { attrName, data, setData } = props;
  const [actionData, setActionData] = useState({ ...data[attrName] });

  useEffect(() => {
    setData({ ...data, [attrName]: actionData });
  }, [actionData]);

  const changeData = setOnChange(actionData, setActionData);

  const attrs = ["url"];
  const booleanAttrs = ["enabled"];
  const videoAttrs = ["videoFileId"];
  const imageAttrs = ["fileId"];

  const attrsData = [
    // { attr: "imageTextUp" },
    // { attr: "imageTextDown" },
    { attr: "title" },
    { attr: "text", multiLine: true },
    { attr: "button" },
  ];

  const uploadFilesHandler =
    (attrName) =>
    ([file]) => {
      setActionData({ ...actionData, [attrName]: file._id });
    };

  return (
    <>
      {booleanAttrs.map((name, index) => {
        return (
          <div className="form-group row" key={index}>
            <label className="col-4 col-form-label">
              <IntlMessages id={`ExtraInfoBlock.${name}`} />
            </label>
            <div className="col-8  my-auto">
              <SwitchValue value={actionData[name]} name={name} onChange={changeData} />
            </div>
          </div>
        );
      })}

      {imageAttrs.map((name, index) => {
        return (
          <div className="form-group row" key={index}>
            <label className="col-4 col-form-label">
              <IntlMessages id={`ExtraInfoBlock.${name}`} />
            </label>
            <div className="col-8 my-auto">
              {actionData[name] ? (
                <ImagePreview
                  file={{ _id: actionData[name] }}
                  deleteHandler={() => {
                    setActionData({ ...actionData, [name]: null });
                  }}
                />
              ) : (
                "Нет"
              )}

              <div className="pull-right">
                {!actionData[name] ? (
                  <Uploader
                    className="btn-success btn-sm pull-right"
                    successHandler={uploadFilesHandler(name)}
                    imagesOnly={true}
                    multiple={false}
                  />
                ) : null}
              </div>
            </div>
          </div>
        );
      })}

      {videoAttrs.map((name, index) => {
        return (
          <div className="form-group row" key={index}>
            <label className="col-4 col-form-label">
              <IntlMessages id={`ExtraInfoBlock.${name}`} />
            </label>
            <div className="col-8 my-auto">
              {actionData[name] ? (
                <VideoPreview
                  file={{ _id: actionData[name] }}
                  deleteHandler={() => {
                    setActionData({ ...actionData, [name]: null });
                  }}
                />
              ) : (
                "Нет"
              )}

              <div className="pull-right">
                {!actionData[name] ? (
                  <Uploader
                    className="btn-success btn-sm pull-right"
                    successHandler={uploadFilesHandler(name)}
                    accept=".mp4"
                    multiple={false}
                  />
                ) : null}
              </div>
            </div>
          </div>
        );
      })}

      {attrsData.map((data, index) => {
        const { attr, multiLine } = data;
        return (
          <div key={index} className="form-group row">
            <div className="col-4 col-form-label">
              <IntlMessages id={`ExtraInfoBlock.${attr}`} />
              <LocalesValues.AddButton
                className="btn-sm float-right"
                values={actionData[attr]}
                changeValues={(values) => setActionData({ ...actionData, [attr]: values })}
              />
            </div>
            <div className="col-8">
              <LocalesValues
                values={actionData[attr]}
                isHtml={false}
                rows={multiLine ? 8 : 1}
                changeValues={(values) => setActionData({ ...actionData, [attr]: values })}
              />
            </div>
          </div>
        );
      })}

      {attrs.map((name, index) => {
        return (
          <div className="form-group row" key={index}>
            <label className="col-4 col-form-label">
              <IntlMessages id={`ExtraInfoBlock.${name}`} />
            </label>
            <div className="col-8">
              <input
                type="text"
                name={name}
                value={actionData[name] || ""}
                onChange={changeData}
                className="form-control react-form-input"
                autoComplete="off"
                required
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ExtraInfoBlock;
