import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

import SettingsForm from "./Form";

const SettingsCommon = (props) => {
  // аттрибуты, которые необходимо отобразить
  const attrs = [
    "phone",
    "email",
    "whatsapp",
    "instagram",
    "facebook",
    "vk",
    "versionNumber",
    "telegram",
    "startBotMessage",
    "supportBotMessage",
    "telegramAppUrl",
    "telegramChannelUrl",
    "telegramCommunityUrl",
    // "freeFriends",
  ];

  // аттрибуты, которые необходимо отобразить
  const localesAttrs = [
    { attr: "shareButtonText" },
    { attr: "shareScreenButton" },
    { attr: "shareScreenTitle" },
    // { attr: "introVideo" },
    { attr: "settingsIntroTitle" },
    { attr: "settingsIntroText", multiLine: true },
    { attr: "settingsWarningText" },
  ];
  const booleanAttrs = ["allowSelectCountry"];
  const htmlAttrs = [
    "settingsErrorText",
    "helpText",
    "stopText",
    // "shareScreenText",
    "softToys",
    "digitalToys",
  ];
  const filesAttrs = [
    "logoImageId",
    "defaultImageId",
    "helpImageId",
    { name: "videoFileId", type: "video", accept: ".mp4" },
  ];

  const componentAttrs = [
    // { name: "adContacts", component: ContactsData },
    // { name: "inviteAction", component: SocialBlockAction },
    // { name: "giftAction", component: SocialBlockAction },
  ];

  return (
    <SettingsForm
      {...{ componentAttrs, filesAttrs, htmlAttrs, booleanAttrs, localesAttrs, attrs }}
    />
  );
};

export default connect(null, {})(SettingsCommon);
