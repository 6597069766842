import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Line } from "react-chartjs-2";
import IntlMessages from "util/intlMessages";
import customTooltip from "components/common/chartTooltip";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
// import { extendMoment } from "dayjs-range";
import { colors } from "./init";

dayjs.extend(relativeTime);

// const dayjs = extendMoment(dayjs);
const DynamicGroupLineChart = (props) => {
  const intl = useIntl();

  const [datasets, setDatasets] = useState([]);
  const [labels, setLabels] = useState([]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    // tooltips: {
    //     mode: "index",
    //     intersect: false
    // },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    spanGaps: true,
    tooltips: {
      enabled: false,
      custom: customTooltip,
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: false,
            labelString: intl.formatMessage({ id: "Dashboard.date" }),
          },
          ticks: {
            fontColor: "#6c757d",
            color: "#6c757d",
            zeroLineColor: "#6c757d",
            fontStyle: "bold",
          },
          gridLines: {
            display: true,
            color: "rgba(0,0,0, 0.1)",
            zeroLineColor: "#6c757d",
          },
        },
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: false,
            labelString: intl.formatMessage({ id: "Dashboard.value" }),
          },
          ticks: {
            display: true,
            fontColor: "#6c757d",
            fontStyle: "bold",
            // stepSize: 1,
            callback: function (value, index, values) {
              return _.toSafeInteger(value);
            },
          },
          gridLines: {
            display: false,
            color: "rgba(0,0,0, 0.1)",
            zeroLineColor: "#6c757d",
          },
        },
      ],
    },
  };

  useEffect(() => {
    const { query } = props;
    const now = props.toDate || new Date();
    const fromDate = dayjs(now).subtract(1, props.scale).toDate();
    // const range = dayjs.range(fromDate, now);

    const keys = [];
    const labels = [];
    const datasets = [];
    const isYear = props.scale === "year";
    const rangeScale = isYear ? "month" : "day";
    const range = dayjs(now).diff(fromDate, rangeScale);

    const rangeFormat = isYear ? "YYYY-M-1" : "YYYY-M-D";
    const dateFormat = isYear ? "MM.YYYY" : intl.formatMessage({ id: "dateFormat" });
    // _.map(Array.from(range.by(rangeScale)), (momentDate) => {
    //   keys.push(momentDate.format(rangeFormat));
    //   labels.push(momentDate.format(dateFormat));
    // });
    _.map(_.range(-range, 1), (value) => {
      const date = dayjs().subtract(Math.abs(value), rangeScale);
      keys.push(date.format(rangeFormat));
      labels.push(date.format(dateFormat));
    });

    const groups = _.groupBy(props.stats, props.groupByKey);
    let colorIndex = 0;
    _.map(props.graphs, (graph, index) => {
      const id = _.get(graph, "_id") || graph;
      const source = groups[id] || {};
      const subGroups = _.groupBy(source, props.subGroupByKey);

      _.forEach(subGroups, (values, subGroupKey) => {
        const color = colors[colorIndex % colors.length];
        let label;

        if (typeof props.label === "function") {
          label = Reflect.apply(props.label, null, [graph, subGroupKey]);
        } else {
          label = props.label
            ? Reflect.get(graph, props.label) ||
              intl.formatMessage({ id: `${props.label}.${graph}` })
            : graph;
        }

        const data = new Array(keys.length);
        const source = _.keyBy(values, "dataAttr");
        _.map(keys, (key, position) => {
          data[position] = _.get(source, `${key}.${props.valueKey}`) || _.get(source, key) || 0;
        });
        colorIndex += 1;

        const dataset = {
          label,
          data,
          color,
          borderColor: color,
          pointBorderColor: color,
          pointBackgroundColor: color,
          pointRadius: 3,
          pointHoverRadius: 6,
          fill: true,
          backgroundColor: color + "13",
        };

        datasets.push(dataset);
      });
    });
    setLabels(labels);
    setDatasets(datasets);
  }, [props]);

  const ScaleSelector = () => {
    const scales = ["week", "month", "year"];
    return (
      <div className="text-right">
        {/* 
        <span className="mr-3">
          <IntlMessages id="Dashboard.scale" />
        </span>
           */}
        {_.map(scales, (scale, index) => {
          const isActive = scale === props.scale;
          return (
            <span
              key={index}
              className={`btn btn-sm mr-3 ${isActive ? "btn-info" : "border-secondary"}`}
              onClick={() => props.setScale(scale)}
            >
              <IntlMessages id={`Dashboard.scale.${scale}`} />
            </span>
          );
        })}
      </div>
    );
  };
  // return <b>chart</b>
  // /*
  return (
    <div className="card roe-shadow-2 fill-height">
      <div className="card-body">
        <div className="row mb-3">
          <div className="col-12 col-md-6 my-auto h4 card-title">
            <IntlMessages id={props.title} />
          </div>
          <div className="col-12 col-md-6">
            <ScaleSelector />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-9">
            <Line data={{ datasets, labels }} height={300} options={options} />
          </div>

          <div className="col-12 col-lg-3">
            <div className="row mt-2">
              {_.map(datasets, (dataset, index) => {
                const { color, label } = dataset;
                return (
                  <div key={index} className="col-6 col-md-4 col-lg-12 mb-3 text-nowrap">
                    <i className="fa fa-circle mr-3" style={{ color }} />
                    {label}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  // */
};

export default DynamicGroupLineChart;
