import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FloatTags } from "components/special/contents";
import { setOnChange } from "components/special";
import adminAPI from "redux/api/admin";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const AddContent = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [newContent, setNewContent] = useState({});
  const [formDisabled, setFormDisabled] = useState(null);
  const changeNewContent = setOnChange(newContent, setNewContent);
  const toggleModal = () => setOpenModal(!openModal);
  const dispatch = useDispatch();
  const { contentForm } = props;

  const addHandler = () => {
    const onSuccess = (body) => {
      dispatch(push(contentForm(body)));
    };
    props.addContent({ body: newContent }, { onSuccess });
  };

  useEffect(() => {
    setFormDisabled(!newContent.name);
  }, [newContent]);

  useEffect(() => {
    setNewContent({});
  }, [openModal]);

  return (
    <>
      <i className="fa fa-plus-square" onClick={toggleModal} />

      {openModal && (
        <Modal isOpen={openModal} centered size="lg" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id="Contents.Add" />
          </ModalHeader>
          <ModalBody>
            <div className="form-group row">
              <div className="col-4">
                <IntlMessages id="Memes.name" />
              </div>
              <div className="col">
                <input
                  type="text"
                  value={newContent.name || ""}
                  name="name"
                  onChange={changeNewContent}
                  required
                  className="form-control"
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="form-group">
              <button
                type="button"
                className="form-control c-primary"
                disabled={formDisabled}
                onClick={addHandler}
              >
                <IntlMessages id="save" />
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addContent: api.post(apiUrls.memes),
})(AddContent);
