/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import dayjs from "dayjs";
import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import adminAPI from "redux/api/admin";
import { FormButton } from "./Form";
import { useIntl } from "react-intl";
import { Pager, Finder, FilterButton, Sorter, TableLoading } from "components/standart";
import { history } from "redux/store";
import { limit } from "variables";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const ContentsTable = (props) => {
  const { localeId } = props;
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [contentData, setContentData] = useState({ total: 0 });
  const [query, setQuery] = useState({});

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  const { contentInfo, contentForm } = props;

  useEffect(() => {
    setQuery({ limit, localeId, ...qs.parse(history.location.search) });
  }, [history.location, localeId]);

  useEffect(() => {
    const onSuccess = (body) => {
      setContentData(body);
    };
    const onRequest = () => {
      setContentData({ total: -1 });
    };

    query.limit && props.getContent({ query }, { onSuccess, onRequest });
  }, [query]);

  return (
    <>
      <Table responsive>
        <thead className="text-nowrap">
          <tr>
            <th>
              <IntlMessages id="tables.title" />
              <Sorter query={query} url={url} order="title" />
            </th>
            <th>
              <IntlMessages id="Www.Contents.url" />
            </th>
            <th>
              <IntlMessages id="tables.created" />
              <Sorter query={query} url={url} order="createdAt" />
            </th>
            <th>
              <Finder.search {...{ query, url }}>
                <div>
                  <FilterButton
                    className="mr-1"
                    on="lock"
                    off="lock-open"
                    id="tables.blocked_filter"
                    name="blocked"
                    {...{ query, setQuery }}
                  />
                </div>
              </Finder.search>
            </th>
          </tr>
        </thead>
        {contentData.total < 0 && <TableLoading />}
        <tbody>
          {_.map(contentData.data, (content, key) => (
            <tr key={key} className={`${!content.enabled ? "text-strike" : ""}`}>
              <td>
                <Link to={contentInfo(content)}>{content.title}</Link>
              </td>
              <td>{content.url}</td>
              <td>{dayjs(content.createdAt).format(dateTimeFormat)}</td>
              <td>
                <FormButton {...{ content, contentForm }} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={contentData.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getContent: api.get(apiUrls.content),
})(ContentsTable);
