/** форма клиента
 */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import dayjs from "dayjs";

import { useIntl } from "react-intl";

const Info = (customer) => {
  const intl = useIntl();

  const dateFormat = intl.formatMessage({ id: "dateFormat" });

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-6">
          <div className="roe-card-style">
            <div className="roe-card-header">{<IntlMessages id="Customers.Data" />}</div>
            <div className="roe-card-body">
              <div className="form-group row">
                <div className="col-4 ">
                  <IntlMessages id="Customers.name" />
                </div>
                <div className="col-8">{customer.name}</div>
              </div>
              <div className="form-group row">
                <div className="col-4 ">
                  <IntlMessages id="Customers.birthDate" />
                </div>
                <div className="col-8">{dayjs(customer.birthDate).format(dateFormat)}</div>
              </div>
              <div className="form-group row">
                <div className="col-4 ">
                  <IntlMessages id="Customers.astroSign" />
                </div>
                <div className="col-8">
                  {customer.astroSign ? (
                    <IntlMessages id={`AstroSigns.${customer.astroSign}`} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 
        <div className="col-12 col-lg-6 col-xl-6">
          <div className="roe-card-style">
            <div className="roe-card-header">{<IntlMessages id="Logins" />}</div>
            <div className="roe-card-body">
              <LoginsTable />
            </div>
          </div>
        </div>
            */}
      </div>
    </> ///
  );
};

export default connect(null, {})(Info);
