import _ from "lodash";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import IntlMessages from "util/intlMessages";
import Select from "react-select";

const PeriodicQuotesTypesSelector = (props) => {
  const { periodicQuotesTypes } = props.envStore;
  const { name = "type", setValue } = props;

  const options = () =>
    _.map(periodicQuotesTypes, (value) => {
      return { value, label: <IntlMessages id={`PeriodicQuotesTypes.${value}`} /> };
    });

  const onChangeHandler = (selectedOptions) => {
    const value = selectedOptions.value;
    const target = { name, value };
    setValue({ target });
  };

  return (
    <Select
      isMulti={false}
      name={name}
      value={
        props.value
          ? { value: props.value, label: <IntlMessages id={`PeriodicQuotesTypes.${props.value}`} /> }
          : null
      }
      options={options()}
      // placeholder={<IntlMessages id="PeriodicQuotesTypes" />}
      onChange={onChangeHandler}
    />
  );
};

const mapStateToProps = (state) => ({
  envStore: state.api.env,
});

export default connect(mapStateToProps, {})(PeriodicQuotesTypesSelector);
