/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import dayjs from "dayjs";
import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { adminAPI } from "redux/api";
import { FormButton } from "./Form";
import { useIntl } from "react-intl";
import { Pager, FilterButton, Finder, Sorter, TableLoading } from "components/standart";
import { history } from "redux/store";

import { apiUrls, typesInfo } from "./init";

const { api } = adminAPI.actions;

const TypesTable = (props) => {
  const limit = 99999;
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [types, setTypes] = useState({ total: 0 });
  const [query, setQuery] = useState({});

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return _.filter(result, _.size);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const newOrder = reorder(types.data, result.source.index, result.destination.index);

    const _id = _.map(newOrder, ({ _id }) => _id);

    props.savePositions(
      { body: { _id } },
      {
        onSuccess() {
          console.log(new Date(), "save priority success");
        },
      }
    );

    setTypes({ ...types, data: newOrder });
  };

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setTypes(body);
    };
    const onRequest = () => {
      setTypes({ total: -1 });
    };

    query.limit && props.getTypes({ query }, { onSuccess, onRequest });
  }, [query]);

  return (
    <>
      <Table responsive>
        <thead className="text-nowrap">
          <tr>
            <th>
              <IntlMessages id="Items.Types.name" />
            </th>
            <th>
              <IntlMessages id="Items.Types.isDigital" />
            </th>
            {/* 
            <th>
              <IntlMessages id="Items.Categories.image" />
            </th>
            */}
            <th>
              <IntlMessages id="tables.created" />
              <Sorter query={query} url={url} order="createdAt" />
            </th>
            <th>
              <Finder.search {...{ query, url }}>
                <div>
                  <FilterButton
                    className="mr-1"
                    on="lock"
                    off="lock-open"
                    id="tables.blocked_filter"
                    name="blocked"
                    {...{ query, setQuery }}
                  />
                </div>
              </Finder.search>
            </th>
          </tr>
        </thead>
        {types.total < 0 && <TableLoading />}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                {_.map(types.data, (type, key) => (
                  <Draggable key={type._id} draggableId={type._id} index={key}>
                    {(provided, snapshot) => (
                      <tr
                        style={{
                          ...(snapshot.isDragging ? { display: "table" } : {}),
                        }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`${!type.enabled ? "text-strike" : ""}`}
                      >
                        <td>
                          <Link to={typesInfo(type)}>{type.name}</Link>
                        </td>
                        <td>
                          <IntlMessages id={`${type.isDigital ? "yes" : "no"}`} />
                        </td>
                        <td>{dayjs(type.createdAt).format(dateTimeFormat)}</td>
                        <td>
                          <FormButton {...{ type }} className="mr-1" />
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>

        <tfoot>
          <tr>
            <td colSpan="99">
              {/* <Pager query={query} url={url} total={types.total} /> */}
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getTypes: api.get(apiUrls.types),
  savePositions: api.post(apiUrls.typesPositions),
})(TypesTable);
