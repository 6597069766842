import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch, Link, useParams, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import PageTitle from "components/standart/PageTitle";
import { FloatAspectsCategories } from "components/special/contents";

import Form from "./Form";
import GenerateResult from "../MemeGenerates/Result";

import adminAPI from "redux/api/admin";
import { apiUrls, jobsForm } from "./init";
const { api } = adminAPI.actions;

const JobsInfo = (props) => {
  const { url, path } = useRouteMatch();

  const params = useParams();

  const [job, setJob] = useState(null);
  const [completion, setCompletion] = useState(null);
  const [data, setData] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const { breadCrumbs } = props;

  if (job) {
    breadCrumbs.push({
      title: job.name,
      url,
      actions: [
        <Link to={jobsForm(job)}>
          <i className="fa fa-edit" />
        </Link>,
      ],
    });
  }

  useEffect(() => {
    const onSuccess = (body) => {
      setJob(body);
    };
    const onFailure = ({ status }) => {
      setNotFound(404 === status);
    };

    props.getJob({ params }, { onSuccess, onFailure });

    props.getJobResults(
      { params },
      {
        onSuccess(body) {
          setData(body);
        },
        onRequest() {
          setData([]);
        },
      }
    );
  }, []);

  const cancelHandler = (resultId) => () => {
    const confirm = window.confirm("Отменить результаты?");
    if (confirm) {
      saveResultMemesHandler(resultId);
    }
  };

  const saveResultMemesHandler = useCallback(
    (resultId) => {
      //
      props.updateJobResults(
        { params, body: { resultId } },
        {
          onSuccess() {
            //
            setData(_.filter(data, (data) => data._id !== resultId));
          },
        }
      );
    },
    [data]
  );

  return (
    <>
      {notFound && <Error400 />}

      {job && (
        <Switch>
          {/* <Route exact path={path} render={() => <Redirect to={`${url}/form`} />} /> */}

          <Route exact path={`${path}`}>
            <div>
              <PageTitle {...breadCrumbs} />

              {_.map(data, (result) => {
                const { completion } = job;
                const settings = { aspectCategoryId: result.categoryId, memeId: [result.memeId] };
                const memes = [];
                const usageData = {};
                _.map(result.value, (value) => {
                  const { resultValues, usage } = value;
                  _.map(resultValues, (resultValue) => {
                    if (completion.dataGroup === "aspects" && resultValue.aspectValueId) {
                      memes.push(resultValue);
                    } else if (completion.dataGroup === "memes" && resultValue.memeId) {
                      memes.push(resultValue);
                    }
                  });
                  _.map(_.keys(usage), (key) => {
                    if (_.isUndefined(usageData[key])) {
                      usageData[key] = +usage[key];
                    } else {
                      usageData[key] = usageData[key] + usage[key];
                    }
                  });
                });
                return (
                  <div key={result._id} className={`${result.processedAt ? "d-none" : "mb-5"}`}>
                    <div className="row">
                      <div className="col">
                        <FloatAspectsCategories
                          _id={result.categoryId}
                          showCount={false}
                          showTitle={true}
                          multi={false}
                        />
                      </div>
                      <div className="col-2 text-right">
                        <button className="btn btn-danger" onClick={cancelHandler(result._id)}>
                          Отменить
                        </button>
                      </div>
                    </div>

                    <GenerateResult
                      {...{ generate: completion, settings, memes, usageData }}
                      successHandler={() => saveResultMemesHandler(result._id)}
                    />
                  </div>
                );
              })}
            </div>
          </Route>

          <Route exact path={`${path}/form`}>
            <Form {...{ breadCrumbs, job, setJob }} />
          </Route>

          <Route component={Error400} />
        </Switch>
      )}
    </>
  );
};

export default connect(null, {
  getJob: api.get(apiUrls.jobInfo),
  getJobResults: api.get(apiUrls.jobResults),
  updateJobResults: api.post(apiUrls.jobResults),
})(JobsInfo);
