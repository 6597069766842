/** форма бренда
 *
 */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import {
  FloatAspectsCategories,
  FloatAspectsValues,
  FloatMemes,
  FloatTags,
} from "components/special/contents";
import { setOnChange } from "components/special";
import { LocalesValues, ZodiacSelector } from "components/standart";

import IntlMessages from "util/intlMessages";

const ContentData = (props) => {
  const { content, setContent } = props;
  const changeContent = setOnChange(content, setContent);

  return (
    <>
      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Contents.textUp" />
          <LocalesValues.AddButton
            className="btn-sm float-right"
            values={content.localesTextUp}
            changeValues={(localesTextUp) => setContent({ ...content, localesTextUp })}
          />
        </label>
        <div className="col">
          <LocalesValues
            values={content.localesTextUp}
            isHtml={false}
            changeValues={(localesTextUp) => setContent({ ...content, localesTextUp })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Contents.textDown" />
          <LocalesValues.AddButton
            className="btn-sm float-right"
            values={content.localesTextDown}
            changeValues={(localesTextDown) => setContent({ ...content, localesTextDown })}
          />
        </label>
        <div className="col">
          <LocalesValues
            values={content.localesTextDown}
            isHtml={false}
            changeValues={(localesTextDown) => setContent({ ...content, localesTextDown })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Contents.text" />
          <LocalesValues.AddButton
            className="btn-sm float-right"
            values={content.localesText}
            changeValues={(localesText) => setContent({ ...content, localesText })}
          />
        </label>
        <div className="col">
          <LocalesValues
            values={content.localesText}
            isHtml={false}
            rows={5}
            changeValues={(localesText) => setContent({ ...content, localesText })}
          />
        </div>
      </div>

      <div className="form-group">
        <div className="row">
          <div className="col-3">
            <IntlMessages id="AstroSigns" />
          </div>
          <div className="col">
            <ZodiacSelector value={content.astroSign} name="astroSign" setValue={changeContent} />
          </div>
        </div>
      </div>

      <div className="form-group row">
        <label className="col-3 col-form-label">
          <IntlMessages id="Memes.Meme" />
        </label>
        <div className="col">
          <FloatMemes
            _id={content.memeId}
            query={{ everything: false }}
            showTitle={true}
            showCount={false}
            multi={false}
            successHandler={([memeId]) => {
              setContent({ ...content, memeId });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default connect(null, {})(ContentData);
