export const apiUrls = {
  list: "/api/customers",
  info: "/api/customers/customer_{customerId}",
  premiums: "/api/customers/customer_{customerId}/premiums",
  logins: "/api/customers/customer_{customerId}/logins",
  treesList: "/api/customers/customer_{customerId}/trees",
  loginInfo: "/api/customers/customer_{customerId}/logins/login_{login_id}",
  loginPassword: "/api/customers/customer_{customerId}/logins/login_{login_id}/password",
  checkLogins: "/api/customers/check-logins",
};


export const path = "/customers";

export const infoLink = (customer) => {
  return `${path}/customer_${customer._id}`;
};

export const formLink = (customer) => {
  return `${infoLink(customer)}/form`;
};
