/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import dayjs from "dayjs";
import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import adminAPI from "redux/api/admin";
import { FormButton } from "./Form";
import { useIntl } from "react-intl";
import { Pager, FilterButton, Finder, Sorter, TableLoading } from "components/standart";
import { history } from "redux/store";
import { limit } from "variables";
import { apiUrls, categoriesInfo, categoriesForm } from "./init";
import CategoryQrCode from "./QrCode";

const { api } = adminAPI.actions;

const CategoriesTable = (props) => {
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [categories, setCategories] = useState({ total: 0 });
  const [query, setQuery] = useState({});

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setCategories(body);
    };
    const onRequest = () => {
      setCategories({ total: -1 });
    };

    query.limit && props.getCategories({ query }, { onSuccess, onRequest });
  }, [query]);

  return (
    <>
      <Table responsive>
        <thead className="text-nowrap">
          <tr>
            <th>
              <IntlMessages id="Items.Categories.name" />
            </th>
            <th>
              <IntlMessages id="Items.Categories.alias" />
            </th>
            {/* 
            <th>
              <IntlMessages id="Items.Categories.image" />
            </th>
            */}
            <th>
              <IntlMessages id="tables.created" />
              <Sorter query={query} url={url} order="createdAt" />
            </th>
            <th>
              <Finder.search {...{ query, url }}>
                <div>
                  <FilterButton
                    className="mr-1"
                    on="lock"
                    off="lock-open"
                    id="tables.blocked_filter"
                    name="blocked"
                    {...{ query, setQuery }}
                  />
                </div>
              </Finder.search>
            </th>
          </tr>
        </thead>
        {categories.total < 0 && <TableLoading />}
        <tbody>
          {_.map(categories.data, (category, key) => (
            <tr key={key} className={`${!category.enabled ? "text-strike" : ""}`}>
              <td>
                <Link to={categoriesInfo(category)}>{category.name}</Link>
              </td>
              <td>{category.alias}</td>
              {/* <td>{category.fileId}</td> */}
              <td>{dayjs(category.createdAt).format(dateTimeFormat)}</td>
              <td>
                <FormButton {...{ category }} className="mr-1" />
                <CategoryQrCode {...{ category }} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={categories.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getCategories: api.get(apiUrls.categories),
})(CategoriesTable);
