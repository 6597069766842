import _ from "lodash";
import React, { useState, useEffect } from "react";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import ContentTable from "./Table";
import { PageTitle } from "components/standart";
import AddContent from "./Add";
import HelpContent from "./Help";
import PlanetsInfo from "./Planets";
import ContentInfo from "./Info";

const PeriodicQuotes = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs } = props;

  const contentInfo = (period) => {
    return `${url}/period_${period._id}`;
  };

  const contentForm = (period) => {
    return `${contentInfo(period)}/form`;
  };

  breadCrumbs.push({
    title: <IntlMessages id="Contents.PeriodicQuotes" />,
    url,
    actions: [<PlanetsInfo />, <HelpContent />, <AddContent {...{ contentForm }} />],
  });

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <PageTitle {...breadCrumbs} />

        <ContentTable {...{ contentInfo, contentForm }} />
      </Route>

      <Route path={`${path}/period_:periodId`}>
        <ContentInfo {...{ breadCrumbs, contentInfo, contentForm }} />
      </Route>

      <Route component={Error400} />
    </Switch>
  );
};

export default PeriodicQuotes;
