import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { GendersSelector, ZodiacSelector } from "components/standart";
import { setOnChange } from "components/special";
import adminAPI from "redux/api/admin";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const AddContent = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [newContent, setNewContent] = useState({});
  const [formDisabled, setFormDisabled] = useState(null);
  const changeNewContent = setOnChange(newContent, setNewContent);
  const toggleModal = () => setOpenModal(!openModal);
  const dispatch = useDispatch();
  const { contentForm } = props;

  const addHandler = () => {
    const onSuccess = (body) => {
      dispatch(push(contentForm(body)));
    };
    props.addContent({ body: newContent }, { onSuccess });
  };

  useEffect(() => {
    setFormDisabled(!(/* newContent.gender && */ (newContent.name && newContent.astroSign)));
  }, [newContent]);

  useEffect(() => {
    setNewContent({});
  }, [openModal]);

  return (
    <>
      <i className="fa fa-plus-square" onClick={toggleModal} />

      {openModal && (
        <Modal isOpen={openModal} centered size="md" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id="Contents.Add" />
          </ModalHeader>
          <ModalBody>
            <div className="form-group row">
              <label className="col col-form-label">
                <IntlMessages id="Contents.name" />
              </label>
              <div className="col">
                <input
                  type="text"
                  name="name"
                  value={newContent.name || ""}
                  onChange={changeNewContent}
                  className="form-control react-form-input"
                  autoComplete="off"
                  required
                />
              </div>
            </div>

            {/* 
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <IntlMessages id="Genders" />
                </div>
                <div className="col">
                  <GendersSelector value={newContent.gender} setValue={changeNewContent} />
                </div>
              </div>
            </div>
            */}

            <div className="form-group">
              <div className="row">
                <div className="col">
                  <IntlMessages id="AstroSigns" />
                </div>
                <div className="col">
                  <ZodiacSelector value={newContent.astroSign} setValue={changeNewContent} />
                </div>
              </div>
            </div>

            <div className="form-group">
              <button
                type="button"
                className="form-control c-primary"
                disabled={formDisabled}
                onClick={addHandler}
              >
                <IntlMessages id="save" />
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addContent: api.post(apiUrls.astroSigns),
})(AddContent);
