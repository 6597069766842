import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { setOnChange } from "components/special";
import { AstroPlanetsSelector } from "components/standart";
import adminAPI from "redux/api/admin";

import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const AddContent = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [newContent, setNewContent] = useState({});
  const changeNewContent = setOnChange(newContent, setNewContent);
  const toggleModal = () => setOpenModal(!openModal);
  const dispatch = useDispatch();
  const { contentForm } = props;

  const addHandler = () => {
    const onSuccess = (body) => {
      dispatch(push(contentForm(body)));
    };
    props.addContent({ body: newContent }, { onSuccess });
  };

  useEffect(() => {
    setNewContent({});
  }, [openModal]);

  return (
    <>
      <i className="fa fa-plus-square" onClick={toggleModal} />

      {openModal && (
        <Modal isOpen={openModal} centered size="md" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id="Contents.Add" />
          </ModalHeader>
          <ModalBody>
            <div className="row form-group">
              <label className="col-4 col-form-label">
                <IntlMessages id="Contents.name" />
              </label>

              <div className="col">
                <input
                  type="text"
                  value={newContent.name || ""}
                  name="name"
                  onChange={changeNewContent}
                  required
                  className="form-control"
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-4 col-form-label">
                <IntlMessages id="Planets" />
              </label>
              <div className="col">
                <AstroPlanetsSelector
                  value={newContent.planet}
                  name="planet"
                  setValue={changeNewContent}
                />
              </div>
            </div>

            <div className="form-group">
              <button
                type="button"
                className="form-control c-primary"
                disabled={!newContent.name}
                onClick={addHandler}
              >
                <IntlMessages id="save" />
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addContent: api.post(apiUrls.compatibilitiesCategories),
})(AddContent);
