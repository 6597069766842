export const apiUrls = {
  aspectsCategories: "/api/contents/aspects_categories",
  aspectsCategoriesPositions: "/api/contents/aspects_categories/positions",
  aspectsCategoriesInfo: "/api/contents/aspects_categories/category_{category_id}",

  compatibilitiesCategories: "/api/contents/compatibilities_categories",
  compatibilitiesCategoriesPositions: "/api/contents/compatibilities_categories/positions",
  compatibilitiesCategoriesInfo: "/api/contents/compatibilities_categories/category_{category_id}",

  aspectsQuotes: "/api/contents/aspects_quotes",
  aspectsQuotesImport: "/api/contents/aspects_quotes/import",
  aspectsQuotesInfo: "/api/contents/aspects_quotes/quote_{quote_id}",

  astroSignsQuotes: "/api/contents/astrosigns_quotes",
  astroSignsQuotesImport: "/api/contents/astrosigns_quotes/import",
  astroSignsQuotesInfo: "/api/contents/astrosigns_quotes/quote_{quote_id}",

  aspectsPlanetsQuotes: "/api/contents/aspects_planets_quotes",
  aspectsPlanetsQuotesImport: "/api/contents/aspects_planets_quotes/import",
  aspectsPlanetsQuotesInfo: "/api/contents/aspects_planets_quotes/quote_{quote_id}",

  aspectsCompatibilities: "/api/contents/aspects_compatibilities",
  aspectsCompatibilitiesImport: "/api/contents/aspects_compatibilities/import",
  aspectsCompatibilitiesInfo: "/api/contents/aspects_compatibilities/compatibility_{value_id}",

  aspectsValues: "/api/contents/aspects_values",
  aspectsValuesInfo: "/api/contents/aspects_values/value_{value_id}",
  aspectsValuesPositions: "/api/contents/aspects_values/positions",

  aspectsDates: "/api/contents/aspects_dates",
  aspectsDatesInfo: "/api/contents/aspects_dates/date_{date_id}",

  periodicQuotes: "/api/contents/periodic_quotes",
  periodicQuotesHelp: "/api/contents/periodic_quotes/help",
  periodicQuotesInfo: "/api/contents/periodic_quotes/period_{periodId}",
  periodicQuotesTexts: "/api/contents/periodic_quotes/period_{periodId}/texts",
  periodicQuotesTextsInfo: "/api/contents/periodic_quotes/period_{periodId}/texts/text_{textId}",
  periodicQuotesTextsImport: "/api/contents/periodic_quotes/period_{periodId}/texts/import",
  periodicQuotesPlanets: "/api/contents/periodic_quotes/planets",
  periodicQuotesPlanetsInfo: "/api/contents/periodic_quotes/planets/value_{valueId}",

  memes: "/api/contents/memes",
  memesCheck: "/api/contents/memes/checkTemplates",
  memesInfo: "/api/contents/memes/meme_{memeId}",
  memesImage: "/api/contents/memes/meme_{memeId}/image",

  tags: "/api/contents/tags",
  tagsInfo: "/api/contents/tags/tag_{tagId}",

  dailyQuotes: "/api/contents/daily_quotes",
  dailyQuotesInfo: "/api/contents/daily_quotes/quote_{quote_id}",
  dailyQuotesCategories: "/api/contents/daily_quotes_categories",
  dailyQuotesCategoriesPositions: "/api/contents/daily_quotes_categories/positions",
  dailyQuotesCategoriesInfo: "/api/contents/daily_quotes_categories/category_{category_id}",
  dailyQuotesTopics: "/api/contents/daily_quotes_topics",
  dailyQuotesTopicsInfo: "/api/contents/daily_quotes_topics/topic_{topic_id}",
  preferences: "/api/contents/preferences",
  preferencesPositions: "/api/contents/preferences/positions",
  preferencesInfo: "/api/contents/preferences/preference_{preference_id}",
  astroSigns: "/api/contents/astro_signs",
  astroSignsInfo: "/api/contents/astro_signs/sign_{sign_id}",
  celebrities: "/api/contents/celebrities",
  celebritiesInfo: "/api/contents/celebrities/celebrity_{celebrityId}",
  astroOverlaps: "/api/contents/astro_overlaps",
  astroOverlapsInfo: "/api/contents/astro_overlaps/overlap_{overlap_id}",
  astroOverlapsGradients: "/api/contents/overlaps_gradients",
  astroOverlapsGradientsInfo: "/api/contents/overlaps_gradients/gradient_{gradient_id}",
  astroOverlapsGradientsTexts: "/api/contents/overlaps_gradients_texts",
  astroOverlapsGradientsTextsInfo: "/api/contents/overlaps_gradients_texts/text_{text_id}",
  astroWeeks: "/api/contents/astro_weeks",
  astroWeeksInfo: "/api/contents/astro_weeks/week_{week_id}",
  countries: "/api/contents/countries",
  countriesInfo: "/api/contents/countries/country_{countryId}",
  contriesPositions: "/api/contents/countries/positions",
  locales: "/api/contents/locales",
  localesInfo: "/api/contents/locales/locale_{localeId}",
  localesPositions: "/api/contents/locales/positions",
  marketplaces: "/api/contents/marketplaces",
  marketplacesInfo: "/api/contents/marketplaces/marketplace_{marketplaceId}",
  marketplacesPositions: "/api/contents/marketplaces/positions",
  premiums: "/api/contents/premiums",
  premiumsInfo: "/api/contents/premiums/premium_{premiumId}",
  premiumsPositions: "/api/contents/premiums/positions",
};
