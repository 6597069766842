/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import dayjs from "dayjs";
import {
  FloatAspectsCategories,
  FloatAspectsValues,
  FloatMemes,
} from "components/special/contents";
import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import adminAPI from "redux/api/admin";
import { FormButton } from "./Form";
import { useIntl } from "react-intl";
import { Finder, FilterButton, Pager, Sorter, TableLoading } from "components/standart";
import Lightbox from "react-image-lightbox";
import { history } from "redux/store";
import { limit } from "variables";
import { apiUrls } from "../init";

import ContentImport from "./Import";

const { api } = adminAPI.actions;

const ContentTable = (props) => {
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [contentData, setContentData] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState(null);
  const [lightboxUrl, setLightboxUrl] = useState(null);

  const refreshHandler = () => setRefresh(Date.now());

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  const { contentForm } = props;

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setContentData(body);
    };
    const onRequest = () => {
      setContentData({ total: -1 });
    };

    query.limit && props.getContent({ query }, { onSuccess, onRequest });
  }, [query, refresh]);

  const lightBoxHandler = (url) => () => {
    setLightboxUrl(url);
  };

  return (
    <>
      {lightboxUrl ? (
        <Lightbox
          mainSrc={lightboxUrl}
          onCloseRequest={lightBoxHandler(null)}
          reactModalStyle={{ overlay: { zIndex: 1099 } }}
        />
      ) : null}

      <Table responsive>
        <thead className="text-nowrap">
          <tr>
            <th>
              <IntlMessages id="Contents.text" />
            </th>
            <th>Верх мема</th>
            <th>Низ мема</th>
            <th>
              <FloatMemes
                _id={query.memeId}
                query={{ everything: false }}
                className="btn-sm mr-1"
                showTitle={false}
                showCount={true}
                multi={true}
                successHandler={([...memeId]) => {
                  setQuery({ ...query, memeId });
                }}
              />
              <IntlMessages id="Memes.Meme" />
            </th>
            <th>Изображение</th>

            <th>
              <IntlMessages id="AstroSigns" />
            </th>
            <th>
              <IntlMessages id="tables.created" />
              <Sorter query={query} url={url} order="createdAt" />
            </th>
            <th className="text-nowrap">
              <Finder.search {...{ query, url }}>
                <div>
                  <FilterButton
                    className="mr-1"
                    on="lock"
                    off="lock-open"
                    id="tables.blocked_filter"
                    name="blocked"
                    {...{ query, setQuery }}
                  />
                </div>
              </Finder.search>
              <span className="mx-1" />
              <ContentImport refreshHandler={refreshHandler} />
            </th>
          </tr>
        </thead>
        {contentData.total < 0 && <TableLoading />}
        <tbody>
          {_.map(contentData.data, (content, key) => {
            return (
              <Fragment key={key}>
                <tr className={`${!content.enabled ? "text-strike" : ""}`}>
                  <td>{_.get(content.localesText, "0.value")}</td>
                  <td>{_.get(content.localesTextUp, "0.value")}</td>
                  <td>{_.get(content.localesTextDown, "0.value")}</td>
                  <td>{content.meme?.name}</td>
                  <td>
                    {content.memeImageUrl ? (
                      <img
                        src={content.memeImageUrl}
                        width="180"
                        onClick={lightBoxHandler(content.memeImageUrl)}
                      />
                    ) : null}
                  </td>
                  <td>
                    <IntlMessages id={`AstroSigns.${content.astroSign}`} />
                  </td>

                  <td>{dayjs(content.createdAt).format(dateTimeFormat)}</td>
                  <td>
                    <FormButton {...{ content, contentForm }} />
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={contentData.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getContent: api.get(apiUrls.astroSignsQuotes),
})(ContentTable);
