import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { useRouteMatch } from "react-router-dom";

import { SocialBlockAction, SwitchValue } from "components/special/forms";
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import { LocalesValues, Uploader, ImagePreview, VideoPreview } from "components/standart";

import IntlMessages from "util/intlMessages";
import { PageTitle } from "components/standart";
import { LoadingIcon, setOnChange } from "components/special";
import { adminAPI } from "redux/api";

import { apiUrls } from "../init";
const { api } = adminAPI.actions;

const SettingsForm = (props) => {
  const { componentAttrs, filesAttrs, htmlAttrs, booleanAttrs, localesAttrs, attrs } = props;

  const [settings, setSettings] = useState({});
  useEffect(() => {
    props.getSettings(
      {},
      {
        onRequest() {
          setSettings({});
          setDataSaved(false);
        },
        onSuccess(body) {
          setSettings(body);
        },
      }
    );
  }, []);

  const [dataSaved, setDataSaved] = useState(false);

  const successAlertRef = useRef(null);

  const changeSettings = setOnChange(settings, setSettings);

  const saveSettingsHandler = () => {
    // ограничим список отправляемых данных, пропустив данные о лендинге
    props.saveSettings(
      {
        body: { ...settings },
      },
      {
        onSuccess(body) {
          setSettings(body);
          setDataSaved(true);
          successAlertRef.current.scrollIntoView();
        },
      }
    );
  };

  function successFloat(attr) {
    return ([value = null]) => {
      setSettings({ ...settings, [attr]: value });
    };
  }

  const uploadFilesHandler =
    (attrName) =>
    ([file]) => {
      setSettings({ ...settings, [attrName]: file._id });
    };

  return settings._id ? (
    <>
      <div className="row">
        <div className="col-12 -col-lg-6">
          <div className="roe-card-style">
            <div className="roe-card-header"></div>
            <div className="roe-card-body">
              <div ref={successAlertRef}>
                <Alert
                  className="alert-success"
                  isOpen={dataSaved}
                  toggle={() => setDataSaved(false)}
                >
                  <IntlMessages id="saved" />
                </Alert>
              </div>

              {attrs.map((name, index) => {
                return (
                  <div className="form-group row" key={index}>
                    <label className="col-4 col-form-label">
                      <IntlMessages id={`Www.Settings.${name}`} />
                    </label>
                    <div className="col-8">
                      <input
                        type="text"
                        name={name}
                        value={settings[name] || ""}
                        onChange={changeSettings}
                        className="form-control react-form-input"
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>
                );
              })}

              {localesAttrs.map((value, index) => {
                const { attr, multiLine, html } = value;
                return (
                  <div key={index} className="form-group row">
                    <div className="col-4 col-form-label">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <IntlMessages id={`Www.Settings.${attr}`} />
                        </div>
                        <div className="flex-shrink-1">
                          <LocalesValues.AddButton
                            className="btn-sm pull-right"
                            values={settings[attr]}
                            changeValues={(values) => setSettings({ ...settings, [attr]: values })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-8">
                      <LocalesValues
                        values={settings[attr]}
                        rows={multiLine ? 5 : 1}
                        isHtml={!!html}
                        changeValues={(values) => setSettings({ ...settings, [attr]: values })}
                      />
                    </div>
                  </div>
                );
              })}

              {htmlAttrs.map((name, index) => {
                return (
                  <div key={index} className="form-group row">
                    <div className="col-4 col-form-label">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <IntlMessages id={`Www.Settings.${name}`} />
                        </div>
                        <div className="flex-shrink-1">
                          <LocalesValues.AddButton
                            className="btn-sm pull-right"
                            values={settings[name]}
                            changeValues={(values) => setSettings({ ...settings, [name]: values })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-8">
                      <LocalesValues
                        values={settings[name]}
                        isHtml={true}
                        changeValues={(values) => setSettings({ ...settings, [name]: values })}
                      />
                    </div>
                  </div>
                );
              })}

              {booleanAttrs.map((name, index) => {
                return (
                  <div className="form-group row" key={index}>
                    <label className="col-4 col-form-label">
                      <IntlMessages id={`Www.Settings.${name}`} />
                    </label>
                    <div className="col-8  my-auto">
                      <SwitchValue value={settings[name]} name={name} onChange={changeSettings} />
                    </div>
                  </div>
                );
              })}

              {filesAttrs.map((attr, index) => {
                const name = _.isString(attr) ? attr : attr.name;
                const type = _.isString(attr) ? "image" : attr.type;
                const accept = _.isString(attr) ? undefined : attr.accept;
                return (
                  <div className="form-group row" key={index}>
                    <label className="col-4 col-form-label">
                      <IntlMessages id={`Www.Settings.${name}`} />
                    </label>
                    <div className="col-8 my-auto">
                      {settings[name] ? (
                        <>
                          {type === "image" ? (
                            <ImagePreview
                              file={{ _id: settings[name] }}
                              deleteHandler={() => {
                                setSettings({ ...settings, [name]: null });
                              }}
                            />
                          ) : null}
                          {type === "video" ? (
                            <VideoPreview
                              file={{ _id: settings[name] }}
                              deleteHandler={() => {
                                setSettings({ ...settings, [name]: null });
                              }}
                            />
                          ) : null}
                        </>
                      ) : (
                        "Нет"
                      )}

                      <div className="pull-right">
                        {!settings[name] ? (
                          <Uploader
                            className="btn-success btn-sm pull-right"
                            successHandler={uploadFilesHandler(name)}
                            accept={accept}
                            imagesOnly={!accept}
                            multiple={false}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div> /*                    
                    <div className="form-group row" key={index}>
                      <label className="col-4 col-form-label">
                        <IntlMessages id={`Www.Settings.${name}`} />
                      </label>
                      <div className="col-8  my-auto">
                        <SwitchValue value={settings[name]} name={name} onChange={changeSettings} />
                      </div>
                    </div>
                    */
                );
              })}

              {componentAttrs.map((elem, index) => {
                return (
                  <div className="mb-3" key={index}>
                    <hr />
                    <h3>
                      <IntlMessages id={`Www.Settings.${elem.name}`} />
                    </h3>

                    <elem.component data={settings} setData={setSettings} attrName={elem.name} />
                  </div>
                );
              })}

              <div className="form-group row">
                <div className="col-6">
                  <button
                    type="button"
                    className="form-control c-primary"
                    onClick={saveSettingsHandler}
                  >
                    <IntlMessages id="save" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <LoadingIcon />
  );
};

export default connect(null, {
  getSettings: api.get(apiUrls.settings),
  saveSettings: api.patch(apiUrls.settings),
})(SettingsForm);
