/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import dayjs from "dayjs";
import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import adminAPI from "redux/api/admin";
import { FormButton } from "./Form";
import { useIntl } from "react-intl";
import { Pager, Finder, FilterButton, Sorter, TableLoading } from "components/standart";
import { history } from "redux/store";
import { limit } from "variables";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const FeedbackTable = (props) => {
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [feedbackData, setFeedbackData] = useState({ total: 0 });
  const [query, setQuery] = useState({});

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  useEffect(() => {
    setQuery({ limit, ...qs.parse(history.location.search) });
  }, [history.location]);

  const { feedbackInfo, feedbackForm } = props;

  useEffect(() => {
    const onSuccess = (body) => {
      setFeedbackData(body);
    };
    const onRequest = () => {
      setFeedbackData({ total: -1 });
    };

    query.limit && props.getFeedback({ query }, { onSuccess, onRequest });
  }, [query]);

  return (
    <>
      <Table responsive>
        <thead className="text-nowrap">
          <tr>
            <th>
              <IntlMessages id="Www.Feedbacks.realDate" />
              <Sorter query={query} url={url} order="realDate" />
            </th>
            <th>
              <IntlMessages id="Marketplaces" />
            </th>
            <th>
              <IntlMessages id="Www.Feedbacks.value" />
            </th>
            <th>
              <IntlMessages id="Www.Feedbacks.indexPage" />
            </th>
            <th>
              <IntlMessages id="Www.Feedbacks.localesName" />
            </th>
            <th>
              <Finder.search {...{ query, url }}>
                <div>
                  <FilterButton
                    className="mr-1"
                    on="lock"
                    off="lock-open"
                    id="tables.blocked_filter"
                    name="blocked"
                    {...{ query, setQuery }}
                  />
                </div>
              </Finder.search>
            </th>
          </tr>
        </thead>
        {feedbackData.total < 0 && <TableLoading />}
        <tbody>
          {_.map(feedbackData.data, (feedback, key) => (
            <tr key={key} className={`${!feedback.enabled ? "text-strike" : ""}`}>
              <td>
                <Link to={feedbackInfo(feedback)}>
                  {feedback.realDate ? dayjs(feedback.realDate).format(dateTimeFormat) : null}
                </Link>
              </td>
              <td>{feedback.marketplace.name}</td>
              <td>{feedback.value}</td>
              <td>
                <IntlMessages id={feedback.indexPage ? "yes" : "no"} />
              </td>
              <td>{feedback.localesName[0]?.value}</td>
              <td>
                <FormButton {...{ feedback, feedbackForm }} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={feedbackData.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getFeedback: api.get(apiUrls.feedback),
})(FeedbackTable);
