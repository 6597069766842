import React, { Fragment, useState, useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
// Redux store provider
import { Provider, useDispatch, useSelector } from "react-redux";
import { store, history, persistor } from "./redux/store";
// Style Root for making media queries to inline css
import { StyleRoot } from "radium";
// Layout Routes
import layoutRoutes, { Auth } from "./routes/index.jsx";
import { AuthInProgress } from "views/pages/index";
import { Dashboard } from "./routes/dashboardRoutes.jsx";
import { Admin } from "./routes/adminRoutes.jsx";
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import { themeConfig } from "./settings";
import config, { getCurrentLanguage } from "./settings/languageConfig";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { pdfjs } from "react-pdf";


import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import { ru } from "date-fns/locale/ru";

// import { PersistGate } from 'redux-persist/integration/react';
import "./styles";
import adminAPI, { accessToken } from "redux/api/admin";

const { session } = adminAPI.actions;

registerLocale("ru", ru);
setDefaultLocale("ru");


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const currentAppLocale = AppLocale[getCurrentLanguage(config.defaultLanguage || "english").locale];

const InnerApp = (props) => {
  const dispatch = useDispatch();
  const sessionStore = useSelector((state) => state.api.session);

  const intl = useIntl();

  useEffect(() => {
    const token = localStorage.getItem(accessToken);
    if (token) {
      const onSuccess = () => {
        // window.cloclo_routes = body.routes;
      };
      dispatch(props.getUser({}, { onSuccess }));
    }
  }, []);

  const CheckLogin = (props) => {
    return sessionStore.authInProgress ? (
      <AuthInProgress />
    ) : sessionStore.account ? (
      <Admin {...props} />
    ) : (
      <Auth {...props} />
    );
  };

  return (
    <Fragment>
      <Helmet>
        <title>{intl.formatMessage({ id: "title" })}</title>
      </Helmet>

      {/* <PersistGate loading={null} persistor={persistor}> */}
      {/*Start layout routes */}
      <Router history={history}>
        <CheckLogin {...props} history={history} />
      </Router>
      {/*End layout routes */}
      {/* </PersistGate> */}
    </Fragment>
  );
};

const App = (props) => {
  return (
    <Fragment>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <ThemeProvider theme={themes[themeConfig.theme]}>
          <StyleRoot>
            <Provider store={store}>
              <InnerApp {...props} {...session.actions} />
            </Provider>
          </StyleRoot>
        </ThemeProvider>
      </IntlProvider>
    </Fragment>
  );
};

export default App;

// If you want to choose different color schema go to settings/index.jsx and set your theme and language.

// If you want to change sidebar nav list then go to util/data/sidebar.jsx
