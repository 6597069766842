/** специальные модули */
import _ from "lodash";
import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";

import TranslatesJobs from "./Translates";

import { BreadCrumbs } from "components/standart/PageTitle";

import { path } from "./init";
import DemoCustomers from "./DemoCustomers";
import MemeGenerates from "./MemeGenerates";
import MemeGeneratesJobs from "./MemeGeneratesJobs";

const Specials = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs = new BreadCrumbs() } = props;

  breadCrumbs.push({
    title: <IntlMessages id="Special" />,
    url,
  });
  return (
    <div className="react-strap-doc">
      <Switch>
        <Route path={`${path}/translates`}>
          <TranslatesJobs {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/meme_generates`}>
          <MemeGenerates {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/jobs_generates`}>
          <MemeGeneratesJobs {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/demo_customers`}>
          <DemoCustomers {...{ breadCrumbs }} />
        </Route>

        <Route exact path={path} render={() => <Redirect to={`${url}/translates`} />} />
        <Route component={Error400} />
      </Switch>
    </div>
  );
};

export default { path, component: Specials };
