/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import dayjs from "dayjs";
import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import adminAPI from "redux/api/admin";
import { FormButton } from "./Form";
import { useIntl } from "react-intl";
import { Pager, Sorter, Finder, FilterButton, TableLoading } from "components/standart";
import { history } from "redux/store";
import { limit } from "variables";
import { jobsInfo, apiUrls } from "./init";
import StartButton from "./StartButton";

const { api } = adminAPI.actions;

const JobsTable = (props) => {
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [jobsData, setJobsData] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState({});

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setJobsData(body);
    };
    const onRequest = () => {
      setJobsData({ total: -1 });
    };

    query.limit && props.getJobs({ query }, { onSuccess, onRequest });
  }, [query, refresh]);

  return (
    <>
      <Table responsive>
        <thead className="text-nowrap">
          <tr>
            <th>
              <IntlMessages id="Special.JobsGenerates.name" />
            </th>
            <th>
              <IntlMessages id="Special.JobsGenerates.completion" />
            </th>
            <th>
              <IntlMessages id="Special.JobsGenerates.memes" />
            </th>
            <th>
              <IntlMessages id="Special.JobsGenerates.categories" />
            </th>
            <th>
              <IntlMessages id="Special.JobsGenerates.status" />
            </th>
            <th>
              <IntlMessages id="Special.JobsGenerates.progress" />
            </th>

            <th>
              <IntlMessages id="tables.created" />
              <Sorter query={query} url={url} order="createdAt" />
            </th>
            <th>
              <Finder.search {...{ query, url }}>
                <div>
                  <FilterButton
                    className="mr-1"
                    on="lock"
                    off="lock-open"
                    id="tables.blocked_filter"
                    name="blocked"
                    {...{ query, setQuery }}
                  />
                </div>
              </Finder.search>
            </th>
          </tr>
        </thead>
        {jobsData.total < 0 && <TableLoading />}
        <tbody>
          {_.map(jobsData.data, (job, key) => (
            <tr key={key} className={`${!job.enabled ? "text-strike" : ""}`}>
              <td>
                <Link to={jobsInfo(job)}>{job.name}</Link>
              </td>
              <td>{job.completion?.name}</td>
              <td>{_.size(job.memesId)}</td>
              <td>{_.size(job.categoriesId)}</td>
              <td>{job.status}</td>
              <td>{_.round(job.progress * 100, 2)}</td>
              <td>
                <div>{dayjs(job.createdAt).format(dateTimeFormat)}</div>
                {/* 
                <div>{job.startedAt && dayjs(job.startedAt).format(dateTimeFormat)}</div>
                <div>{job.completedAt && dayjs(job.completedAt).format(dateTimeFormat)}</div>
                 */}
              </td>
              <td>
                <FormButton {...{ job }} />
                <StartButton {...{ job }} refreshTable={() => setRefresh(Date.now())} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={jobsData.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getJobs: api.get(apiUrls.jobs),
})(JobsTable);
