import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch, Link, useParams, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import { Redirect } from "react-router-dom";

import Form from "./Form";

import adminAPI from "redux/api/admin";
import { apiUrls } from "../init";
const { api } = adminAPI.actions;

const FeedbackInfo = (props) => {
  const { url, path } = useRouteMatch();

  const params = useParams();

  const [feedback, setFeedback] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const { breadCrumbs, feedbackForm } = props;

  if (feedback) {
    breadCrumbs.push({
      title: feedback.title,
      url,
      actions: [
        <Link to={feedbackForm(feedback)}>
          <i className="fa fa-edit" />
        </Link>,
      ],
    });
  }

  useEffect(() => {
    const onSuccess = (body) => {
      setFeedback(body);
    };
    const onFailure = ({ status }) => {
      setNotFound(404 === status);
    };

    props.getFeedback({ params }, { onSuccess, onFailure });
  }, []);

  return (
    <>
      {notFound && <Error400 />}

      {feedback && (
        <Switch>
          <Route exact path={path} render={() => <Redirect to={`${url}/form`} />} />

          <Route exact path={`${path}/form`}>
            <Form {...{ breadCrumbs, feedback, setFeedback }} />
          </Route>

          <Route component={Error400} />
        </Switch>
      )}
    </>
  );
};

export default connect(null, {
  getFeedback: api.get(apiUrls.feedbackInfo),
})(FeedbackInfo);
