import _ from "lodash";
import React, { useState, useEffect } from "react";

import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import ContentTable from "./Table";
import { PageTitle } from "components/standart";

import ContentInfo from "./Info";

const PeriodicQuotesTexts = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs } = props;

  const contentInfo = (text) => {
    return `${url}/text_${text._id}`;
  };

  const contentForm = (content) => {
    return `${contentInfo(content)}/form`;
  };

  return (
    <Switch>
      <Route path={`${path}/text_:textId`}>
        <ContentInfo {...{ breadCrumbs, contentInfo, contentForm }} />
      </Route>

      <Route exact path={`${path}`}>
        <PageTitle {...breadCrumbs} />
        <ContentTable {...{ contentInfo, contentForm }} />
      </Route>

      <Route component={Error400} />
    </Switch>
  );
};

export default PeriodicQuotesTexts;
