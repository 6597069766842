/** модальное окно для выбора стран в требуемых случаях */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import adminAPI from "redux/api/admin";
import { FloatModal } from "components/special";

const { api } = adminAPI.actions;

const FloatPremiumsModal = (props) => {
  return (
    <FloatModal
      limit={100}
      header={<IntlMessages id="Premiums" />}
      {...props}
      head={(searchTags, searchQuery, setSearchQuery) => {
        return (
          <>
            <th>
              <IntlMessages id="Premiums.name" />
            </th>
            <th>
              <IntlMessages id="Premiums.duration" />
            </th>
            <th style={{ width: "30px" }}>
              {/* <AddContent successHandler={() => setSearchQuery({})} /> */}
            </th>
          </>
        );
      }}
      row={(value, clickHandler) => (
        <>
          <td onClick={clickHandler} className={!value.enabled ? "text-strike" : ""}>
            {value.name}
          </td>
          <td onClick={clickHandler} className={!value.enabled ? "text-strike" : ""}>
            {value.duration}
          </td>
          <td></td>
        </>
      )}
    />
  );
};

const FloatPremiums = (props) => {
  return <FloatPremiumsModal {...props} />;
};
export default connect(null, {
  getData: api.get("/api/contents/premiums"),
})(FloatPremiums);
