/** управление данными для сайта
 */
import _ from "lodash";
import React, { useEffect } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";
import Content from "./Content";
import Faq from "./Faq";
import Settings from "./Settings";
import Meta from "./Meta";
import Landing from "./Landing";
import { BreadCrumbs } from "components/standart/PageTitle";
import Feedbacks from "./Feedbacks";
import Contacts from "./Contacts";

const path = "/www";
const Www = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs = new BreadCrumbs() } = props;

  breadCrumbs.push({
    title: <IntlMessages id="Www" />,
    url,
  });
  return (
    <div className="react-strap-doc">
      <Switch>
        {/* 
       


        <Route path={`${path}/feedbacks`}>
          <Feedbacks {...{ breadCrumbs }} />
        </Route>

				*/}

        <Route path={`${path}/contents`}>
          <Content {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/contacts`}>
          <Contacts {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/faq`}>
          <Faq {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/feedbacks`}>
          <Feedbacks {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/settings`}>
          <Settings {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/landing`}>
          <Landing {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/meta`}>
          <Meta {...{ breadCrumbs }} />
        </Route>

        <Route exact path={path} render={() => <Redirect to={`${url}/settings`} />} />
        <Route component={Error400} />
      </Switch>
    </div>
  );
};

export default { path, component: Www };
