import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { push } from "react-router-redux";
import IntlMessages from "util/intlMessages";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { GendersSelector, PeriodicQuotesTypesSelector, ZodiacSelector } from "components/standart";
import DatePicker from "react-datepicker";
import { setOnChange } from "components/special";
import adminAPI from "redux/api/admin";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const AddContent = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [newContent, setNewContent] = useState({});
  const [formDisabled, setFormDisabled] = useState(null);
  const changeNewContent = setOnChange(newContent, setNewContent);
  const toggleModal = () => setOpenModal(!openModal);
  const dispatch = useDispatch();
  const { contentForm } = props;
  const dateFormat = "dd.MM.yyyy ";

  const addHandler = () => {
    const onSuccess = (body) => {
      dispatch(push(contentForm(body)));
    };
    props.addContent({ body: newContent }, { onSuccess });
  };

  useEffect(() => {
    setFormDisabled(!(newContent.toDate && newContent.fromDate && newContent.type));
  }, [newContent]);

  useEffect(() => {
    setNewContent({});
  }, [openModal]);

  return (
    <>
      <i className="fa fa-plus-square" onClick={toggleModal} />

      {openModal && (
        <Modal isOpen={openModal} centered size="md" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id="Contents.Add" />
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <IntlMessages id="Contents.AstroWeeks.fromDate" />
                </div>
                <div className="col">
                  <DatePicker
                    selected={newContent.fromDate ? new Date(newContent.fromDate) : ""}
                    dateFormat={dateFormat}
                    popperPlacement="bottom-start"
                    onChange={(value) =>
                      setNewContent({
                        ...newContent,
                        [`fromDate`]: value ? value.toJSON() : null,
                      })
                    }
                    className="form-control"
                    calendarClassName="custom-calender-class"
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col">
                  <IntlMessages id="Contents.AstroWeeks.toDate" />
                </div>
                <div className="col">
                  <DatePicker
                    selected={newContent.toDate ? new Date(newContent.toDate) : ""}
                    dateFormat={dateFormat}
                    popperPlacement="bottom-start"
                    onChange={(value) =>
                      setNewContent({
                        ...newContent,
                        [`toDate`]: value ? value.toJSON() : null,
                      })
                    }
                    className="form-control"
                    calendarClassName="custom-calender-class"
                  />
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col col-form-label">
                <IntlMessages id="PeriodicQuotesTypes" />
              </label>
              <div className="col">
                <PeriodicQuotesTypesSelector value={newContent.type} setValue={changeNewContent} />
              </div>
            </div>

            <div className="form-group">
              <button
                type="button"
                className="form-control c-primary"
                disabled={formDisabled}
                onClick={addHandler}
              >
                <IntlMessages id="save" />
              </button>
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default connect(null, {
  addContent: api.post(apiUrls.periodicQuotes),
})(AddContent);
