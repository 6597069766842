/** форма бренда
 *
 */
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { setOnChange } from "components/special";
import { AstroAspectsSelector, LocalesValues } from "components/standart";

const ContentData = (props) => {
  const { content, setContent } = props;

  const changeContent = setOnChange(content, setContent);

  return (
    <div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Contents.name" />
        </label>
        <div className="col">
          <input
            type="text"
            name="name"
            value={content.name || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Contents.minValue" />
        </label>
        <div className="col">
          <input
            type="number"
            name="minValue"
            step={0.5}
            value={content.minValue || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Contents.maxValue" />
        </label>
        <div className="col">
          <input
            type="number"
            name="maxValue"
            step={0.5}
            value={content.maxValue || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>

      {/* 
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="AstroAspects" />
        </label>
        <div className="col">
          <AstroAspectsSelector
            value={content.astroAspect}
            name="astroAspect"
            setValue={changeContent}
          />
        </div>
      </div>
      */}

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Contents.title" />
          <LocalesValues.AddButton
            className="btn-sm float-right"
            values={content.localesName}
            changeValues={(localesName) => setContent({ ...content, localesName })}
          />
        </label>
        <div className="col">
          <LocalesValues
            values={content.localesName}
            changeValues={(localesName) => setContent({ ...content, localesName })}
          />
        </div>
      </div>

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Contents.subTitle" />
          <LocalesValues.AddButton
            className="btn-sm float-right"
            values={content.localesText}
            changeValues={(localesText) => setContent({ ...content, localesText })}
          />
        </label>
        <div className="col">
          <LocalesValues
            values={content.localesText}
            changeValues={(localesText) => setContent({ ...content, localesText })}
          />
        </div>
      </div>
      {/*
      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Contents.text" />
          <LocalesValues.AddButton
            className="btn-sm float-right"
            values={content.localesText}
            changeValues={(localesText) => setContent({ ...content, localesText })}
          />
        </label>
        <div className="col">
          <LocalesValues
            values={content.localesText}
            isHtml={true}
            changeValues={(localesText) => setContent({ ...content, localesText })}
          />
        </div>
      </div>
      */}

      <div className="form-group row">
        <label className="col-4 col-form-label">
          <IntlMessages id="Contents.icon" />
        </label>
        <div className="col-8">
          <input
            type="text"
            name="icon"
            value={content.icon || ""}
            onChange={changeContent}
            className="form-control react-form-input"
            autoComplete="off"
            required
          />
        </div>
      </div>
    </div>
  );
};

export default connect(null, {})(ContentData);
