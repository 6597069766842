/** управление партнерами
 */
import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import dayjs from "dayjs";
import { useIntl } from "react-intl";
import { useParams, Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import adminAPI from "redux/api/admin";
import { history } from "redux/store";
import { FloatPremiums } from "components/special/contents";
import { Finder, Pager, Sorter, TableLoading } from "components/standart";
import { limit } from "variables";

import { apiUrls, infoLink, formLink } from "../init";
const { api } = adminAPI.actions;

const CustomerPremiums = (props) => {
  const { url, path } = useRouteMatch();
  const params = useParams();
  const intl = useIntl();

  const [premiums, setPremiums] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [isLoading, setLoading] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setPremiums(body);
    };
    const onRequest = () => {
      setPremiums({ total: -1 });
    };

    query.limit &&
      props.getData(
        { query: { order: "-createdAt", ...query, ...params }, params },
        { onSuccess, onRequest }
      );
  }, [query, refresh]);

  const addPremiumHandler = useCallback((premiumId) => {
    props.addData(
      { params, body: { premiumId } },
      {
        onRequest() {
          setLoading(true);
        },
        onSuccess() {
          setLoading(false);
          setRefresh(new Date());
        },
      }
    );
  }, []);

  return (
    <>
      <Table responsive style={{ position: "relative" }}>
        <thead>
          <tr>
            <th>
              <IntlMessages id="Premiums.name" />
            </th>
            <th>
              <IntlMessages id="Premiums.isGift" />
            </th>
            <th>
              <IntlMessages id="Premiums.expiredAt" />
              <Sorter query={query} url={url} order="expiredAt" />
            </th>

            <th>
              <IntlMessages id="tables.created" />
              <Sorter query={query} url={url} order="createdAt" />
            </th>

            <th>
              <FloatPremiums
                className="btn-sm btn-success"
                icon={isLoading ? "fa-spinner fa-spin" : "fa-plus-circle"}
                disabled={!!isLoading}
                header="Подарить премуим пользователю"
                multi={false}
                successHandler={([premiumId]) => {
                  premiumId && addPremiumHandler(premiumId);
                }}
              />
            </th>
          </tr>
        </thead>
        {premiums.total < 0 && <TableLoading />}
        <tbody>
          {_.map(premiums.data, (value, key) => {
            return (
              <tr key={key} className={!value.enabled ? "text-strike" : ""}>
                <td>{value.premium?.name}</td>
                <td>{<IntlMessages id={value.paymentId ? "no" : "yes"} />}</td>

                <td>{dayjs(value.expiredAt).format(dateTimeFormat)}</td>
                <td>{dayjs(value.createdAt).format(dateTimeFormat)}</td>

                <td></td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={premiums.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getData: api.get(apiUrls.premiums),
  addData: api.post(apiUrls.premiums),
})(CustomerPremiums);
