import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import IntlMessages from "util/intlMessages";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Table } from "reactstrap";
import { AstroPlanetsSelector, PeriodicQuotesTypesSelector } from "components/standart";

import DatePicker from "react-datepicker";
import { LoadingIcon, setOnChange } from "components/special";
import adminAPI from "redux/api/admin";
import { apiUrls } from "../init";

const { api } = adminAPI.actions;

const PlanetsContent = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [content, setContent] = useState({});
  const [loading, setLoading] = useState(null);
  const toggleModal = () => setOpenModal(!openModal);

  const loadContent = () => {
    props.getPlanets(
      {},
      {
        onRequest() {
          setContent({});
          setLoading(true);
        },
        onSuccess(data) {
          setContent(data);
          setLoading(false);
        },
      }
    );
  };

  const addValueHandler = () => {
    const confirm = window.confirm("Добавить значение?");
    if (confirm) {
      props.addPlanets(
        {},
        {
          onSuccess() {
            loadContent();
          },
        }
      );
    }
  };

  useEffect(() => {
    if (openModal) {
      loadContent();
    }
  }, [openModal]);

  return (
    <>
      <i className="fa fa-cog" onClick={toggleModal} />

      {openModal && (
        <Modal isOpen={openModal} centered size="xl" toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Планеты по типам контента</ModalHeader>
          <ModalBody>
            {loading ? (
              <LoadingIcon />
            ) : (
              <Table>
                <thead>
                  <tr>
                    <th className="w-25">
                      <IntlMessages id="PeriodicQuotesTypes" />
                    </th>
                    <th className="">
                      <IntlMessages id="Planets" />
                    </th>
                    <th className="w-10">
                      <button className="btn btn-sm btn-success" onClick={addValueHandler}>
                        <i className="fa fa-plus" />
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(content.data, (value) => {
                    return <ValueRow key={value._id} {...value} />;
                  })}
                </tbody>
              </Table>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

const saveValues = api.patch(apiUrls.periodicQuotesPlanetsInfo);
const deleteValues = api.delete(apiUrls.periodicQuotesPlanetsInfo);

const ValueRow = (value) => {
  const [data, setData] = useState(value);
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const changeContent = setOnChange(data, setData);

  const saveValueHandler = useCallback(() => {
    const confirm = window.confirm("Сохранить значение?");
    if (confirm) {
      const body = { ...data };
      const params = { valueId: data._id };
      dispatch(
        saveValues(
          { params, body },
          {
            onRequest() {
              setError(false);
              setLoading(true);
            },
            onSuccess() {
              setLoading(false);
            },
            onFailure() {
              setError(true);
              setLoading(false);
            },
          }
        )
      );
    }
  }, [data]);

  const deleteValueHandler = useCallback(() => {
    const confirm = window.confirm("Удалить значение?");
    if (confirm) {
      const params = { valueId: data._id };
      dispatch(
        deleteValues(
          { params },
          {
            onRequest() {
              setLoading(true);
            },
            onSuccess() {
              setData({});
              setLoading(false);
            },
          }
        )
      );
    }
  }, [data]);

  const changePlanetHandler = useCallback(
    (index) => (event) => {
      const { target } = event;
      const { value, name, type } = target;
      const { planets = [] } = data;
      planets[index] = value;
      setData({ ...data, planets });
    },
    [data]
  );

  const removePlanetHandler = useCallback(
    (index) => () => {
      const planets = [];
      _.forEach(data.planets, (planet, _index) => {
        if (index !== _index) {
          planets.push(planet);
        }
      });
      setData({ ...data, planets });
    },
    [data]
  );

  const addPlanetHandler = useCallback(() => {
    const { planets = [] } = data;
    planets.push(null);
    setData({ ...data, planets });
  }, [data]);

  return data._id ? (
    <tr className={isError ? "bg-danger" : null}>
      <td>
        <PeriodicQuotesTypesSelector value={data.type} setValue={changeContent} />
      </td>
      <td>
        <div className="d-flex flex-wrap">
          {_.map(data.planets, (planet, index) => {
            return (
              <div key={index} className="mr-2 mb-2 w-25">
                <div className="d-flex flex-nowrap">
                  <div className="mr-2 flex-grow-1">
                    <AstroPlanetsSelector
                      value={planet}
                      name="planet"
                      setValue={changePlanetHandler(index)}
                    />
                  </div>
                  <div>
                    <button className="btn btn-sm btn-danger" onClick={removePlanetHandler(index)}>
                      <i className="fa fa-minus" />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
          <div>
            <button className="btn btn-sm btn-primary" onClick={addPlanetHandler}>
              <i className="fa fa-plus" />
            </button>
          </div>
        </div>
      </td>
      <td className="text-nowrap">
        {isLoading ? (
          <LoadingIcon />
        ) : (
          <div>
            <button className="btn btn-sm btn-success mr-1" onClick={saveValueHandler}>
              <i className="fa fa-save" />
            </button>
            <button className="btn btn-sm btn-danger" onClick={deleteValueHandler}>
              <i className="fa fa-times" />
            </button>
          </div>
        )}
      </td>
    </tr>
  ) : null;
};

export default connect(null, {
  getPlanets: api.get(apiUrls.periodicQuotesPlanets),
  addPlanets: api.post(apiUrls.periodicQuotesPlanets),
})(PlanetsContent);
