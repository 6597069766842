import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch, Link, useParams, useRouteMatch } from "react-router-dom";
import { Uploader } from "components/standart";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { LoadingIcon } from "components/special";
import IntlMessages from "util/intlMessages";

import adminAPI from "redux/api/admin";
import { apiUrls } from "../init";
const { api } = adminAPI.actions;

const ContentImport = (props) => {
  const [importFileId, setImportFileId] = useState(null);

  const [parseResult, setParseResult] = useState(null);
  const [parseError, setParseError] = useState(null);
  const [parseProcess, setParseProcess] = useState(null);
  const [saveProcess, setSaveProcess] = useState(null);

  const closeModal = () => setImportFileId(null);

  useEffect(() => {
    if (importFileId) {
      const query = { fileId: importFileId };
      props.parseImportFile(
        { query },
        {
          onRequest() {
            setParseProcess(true);
            setParseResult(null);
          },
          onSuccess({ data }) {
            setParseProcess(false);
            setParseResult(data);
          },
          onFailure(error) {
            setParseProcess(false);
            setParseError(error);
          },
        }
      );
    }
  }, [importFileId]);

  const saveDataHandler = useCallback(() => {
    const confirm = window.confirm(
      "Сохранить данные? Строки с красными значениями сохранены не будут!"
    );
    if (confirm) {
      const query = { fileId: importFileId };

      props.saveImportFile(
        { query },
        {
          onRequest() {
            setSaveProcess(true);
          },
          onSuccess() {
            setSaveProcess(false);
            setParseResult(null);
            setImportFileId(null);
            props.refreshHandler();
            // тут надо запустить перезагрузку таблицы
          },
          onFailure(error) {
            setSaveProcess(false);
          },
        }
      );
    }
  }, [importFileId]);

  const uploadFilesHandler = ([file]) => {
    setImportFileId(file._id);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Uploader
        className="btn-success btn-sm"
        successHandler={uploadFilesHandler}
        accept=".csv, .xlsx"
        multiple={false}
      />

      {importFileId ? (
        <>
          <Modal isOpen={true} toggle={closeModal} size="xl">
            <ModalHeader toggle={closeModal}></ModalHeader>
            <ModalBody>
              {parseProcess ? <LoadingIcon /> : null}
              {parseResult ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Мем</th>
                      <th>Тексты</th>
                      <th>Планета</th>
                      <th>Аспект</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {_.map(parseResult, (data, index) => {
                      return (
                        <tr key={index}>
                          <td className={`${data.meme.id ? "text-success" : "text-danger"}`}>
                            {data.meme.name}
                          </td>
                          <td>
                            <div>{data.textUp}</div>
                            <div>{data.textDown}</div>
                            <hr />
                            <div>{data.text}</div>
                          </td>
                          <td className={`${data.planet ? "text-success" : "text-danger"}`}>
                            {data.planet ? (
                              <IntlMessages id={`Planets.${data.planet}`} />
                            ) : (
                              "---"
                            )}
                          </td>
                          <td className={`${data.aspect.id ? "text-success" : "text-danger"}`}>
                            {data.aspect.name}
                          </td>
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={99}>
                        <button
                          className="btn btn-primary"
                          onClick={saveDataHandler}
                          disabled={saveProcess}
                        >
                          {saveProcess ? <LoadingIcon className="mr-2" /> : null}
                          <IntlMessages id="save" />
                        </button>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              ) : null}
            </ModalBody>
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default connect(null, {
  parseImportFile: api.get(apiUrls.aspectsPlanetsQuotesImport),
  saveImportFile: api.post(apiUrls.aspectsPlanetsQuotesImport),
})(ContentImport);
