/** управление брендами
 */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import { Table } from "reactstrap";
import dayjs from "dayjs";
import { Link, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import adminAPI from "redux/api/admin";
import { FormButton } from "./Form";
import { useIntl } from "react-intl";
import { Pager, Sorter, Finder, FilterButton, TableLoading } from "components/standart";
import { history } from "redux/store";
import { limit } from "variables";
import { generateInfo, apiUrls } from "./init";

const { api } = adminAPI.actions;

const GeneratesTable = (props) => {
  const { url } = useRouteMatch();
  const intl = useIntl();
  const [data, setData] = useState({ total: 0 });
  const [query, setQuery] = useState({});
  const [refresh, setRefresh] = useState({});

  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  useEffect(() => {
    setQuery({ limit, order: "-createdAt", ...qs.parse(history.location.search) });
  }, [history.location]);

  useEffect(() => {
    const onSuccess = (body) => {
      setData(body);
    };
    const onRequest = () => {
      setData({ total: -1 });
    };

    query.limit && props.getJobs({ query }, { onSuccess, onRequest });
  }, [query, refresh]);

  return (
    <>
      <Table responsive>
        <thead className="text-nowrap">
          <tr>
            <th>
              <IntlMessages id="Special.MemeGenerates.name" />
            </th>
            <th>
              <IntlMessages id="Special.MemeGenerates.content" />
            </th>
            <th>
              <IntlMessages id="Special.MemeGenerates.gptModel" />
            </th>

            <th>
              <IntlMessages id="tables.created" />
              <Sorter query={query} url={url} order="createdAt" />
            </th>
            <th>
              <Finder.search {...{ query, url }}>
                <div>
                  <FilterButton
                    className="mr-1"
                    on="lock"
                    off="lock-open"
                    id="tables.blocked_filter"
                    name="blocked"
                    {...{ query, setQuery }}
                  />
                </div>
              </Finder.search>
            </th>
          </tr>
        </thead>
        {data.total < 0 && <TableLoading />}
        <tbody>
          {_.map(data.data, (generate, key) => (
            <tr key={key} className={`${!generate.enabled ? "text-strike" : ""}`}>
              <td>
                <Link to={generateInfo(generate)}>{generate.name}</Link>
              </td>
              <td>{generate.content}</td>
              <td>{generate.gptModel}</td>
              <td>{dayjs(generate.createdAt).format(dateTimeFormat)}</td>
              <td>
                <FormButton {...{ generate }} />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="99">
              <Pager query={query} url={url} total={data.total} />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
};

export default connect(null, {
  getJobs: api.get(apiUrls.generates),
})(GeneratesTable);
